import React, { useEffect } from 'react';
import { getPrepaidInvalidAmountMessage } from '@/utils/getPrepaidInvalidAmountMessage';
import { getStoreType } from '@/utils/getStoreType';
import { checkIfObjectExists } from '@/utils/checkIfObjectExists';
import { getPaymentMethodName } from '@/utils/getPaymentMethodName';
import { deviceWidth } from '@/utils/deviceWidth';
import { getMobileOperatingSystem } from '@/utils/operatingSystem';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  fetchPaymentMethods,
  prePaymentCheckMDRTransactions,
  setCustomerTransactionPaymentMethod,
} from 'src/redux/actions';
import { MINIMUM_ORDER_AMOUNT, PAYMENT_TYPE_CONSTANTS } from '@/utils/constants';
import { toastNotifyError } from '../Common/Toast';
import { IS_SERVER } from '@/utils/checkRenderEnv';
import { IS_WEBVIEW } from '../../utils/checkRenderEnv';

const PaymentOptionsGrid = ({ onSelect }) => {
  const dispatch = useDispatch();

  const {
    store,
    orderDetails,
    methods,
    downPaymentMethods,
    paymentOptionsWithTxnCharge,
  } = useSelector((state) => ({
    store: state.storeReducer.store,
    orderDetails: state.orderReducer.orderDetails,
    methods: state.paymentReducer.methods,
    downPaymentMethods: state.paymentReducer.downPaymentMethods,
    paymentOptionsWithTxnCharge: state.paymentReducer.paymentOptionsWithTxnCharge,
  }));

  useEffect(() => {
    store?.store_id &&
      orderDetails?.order_hash &&
      dispatch(
        fetchPaymentMethods({
          store_id: store?.store_id,
          hash: orderDetails?.order_hash,
        })
      );
  }, [store, orderDetails?.order_hash]);

  const tapOptionForCustomerPayment = (option, item) => {
    /*
      MDR and EMI options are exclusive now.
      So if EMI option is selected then, 
      prePaymentCheckMDRTransactions is now not required.

      if emi payment method selected, then we can avoid using the 
      prePaymentCheckMDRTransactions api.

      Here we can go directly to initPayment for payment using emi
    */
    // if (option === PAYMENT_TYPE_CONSTANTS.EMI) {
    //   onTapOption(option, item);
    //   return;
    // }
    if (item && checkIfObjectExists(item)) {
      const payload = {
        order_hash: orderDetails?.order_hash,
        store_id: orderDetails?.store_id,
        payment_option: item,
      };
      const callback = (isTrue) => {
        if (isTrue) {
          onTapOption(option, item);
          return;
        }
        return;
      };
      batch(() => {
        dispatch(setCustomerTransactionPaymentMethod(item));
        dispatch(prePaymentCheckMDRTransactions(payload, callback));
      });
    }
  };

  const onTapOption = (option, selectedPaymentMethod = {}) => {
    if (orderDetails?.buyer_pay_value < MINIMUM_ORDER_AMOUNT) {
      toastNotifyError(getPrepaidInvalidAmountMessage(getStoreType()));
    } else {
      onSelect(option, false, selectedPaymentMethod);
    }
  };

  /**
   * Function to return `card` if item is of type
   * debitCard or creditCard
   * otherwise return as it is
   * @param {*} item
   * @return {*} string
   */
  const returnCardForCCAndDC = (item) => {
    if (
      item === PAYMENT_TYPE_CONSTANTS.DEBIT_CARD ||
      item === PAYMENT_TYPE_CONSTANTS.CREDIT_CARD
    ) {
      return PAYMENT_TYPE_CONSTANTS.CARD;
    }
    return item;
  };

  const removePaytmFromMethods = (methods = [], isObject = false) => {
    // removing paytm form react native webview environment as it is not working properly in webview
    const modes = methods || [];
    const indexOfPaytm = isObject
      ? modes.findIndex(
          (ele) => ele?.payment_option_name === PAYMENT_TYPE_CONSTANTS.PAYTM
        )
      : modes.indexOf(PAYMENT_TYPE_CONSTANTS.PAYTM);
    if (indexOfPaytm > -1 && IS_WEBVIEW) {
      modes.splice(indexOfPaytm, 1);
    }
    return modes;
  };

  /**
   * render component when mdr_flag is 1
   * @return {*}
   */
  const renderPaymentOptionOnCustomerTransaction = () => {
    if (checkIfObjectExists(paymentOptionsWithTxnCharge)) {
      const { down_payment_modes, payment_modes } = paymentOptionsWithTxnCharge;
      return (
        <>
          <ul>
            {removePaytmFromMethods(payment_modes, true)?.map((item) => {
              if (
                !IS_SERVER &&
                deviceWidth > 990 &&
                item.payment_option_name !== PAYMENT_TYPE_CONSTANTS.PAYTM
              )
                return <></>;
              else if (
                item === 'whatsapp' &&
                !IS_SERVER &&
                getMobileOperatingSystem() !== 'Android'
              )
                return <></>;
              else
                return (
                  item.payment_option_name !== PAYMENT_TYPE_CONSTANTS.AMEX_CARD && (
                    <li
                      className={item.payment_option_name}
                      onClick={() =>
                        tapOptionForCustomerPayment(
                          returnCardForCCAndDC(item.payment_option_name),
                          item
                        )
                      }
                    >
                      <div className="logo-wrap">
                        <img
                          src={
                            item?.payment_option_name === 'paytmupi'
                              ? 'https://cdn.dotpe.in/kiranaStatic/custom_website/paytmupilogo21august2023.png'
                              : `//cdn.dotpe.in/cfe/image/${returnCardForCCAndDC(
                                  item.payment_option_name
                                )}.png`
                          }
                          alt="logo"
                        />
                      </div>
                      <span>{getPaymentMethodName(item.payment_option_name)}</span>
                      {!!item.transaction_charge && (
                        <span className="transaction-charge-text">
                          {item.transaction_charge}% convenience fee
                        </span>
                      )}
                    </li>
                  )
                );
            })}
          </ul>
          {down_payment_modes && down_payment_modes.length > 0 && (
            <>
              <div className="pt4 pb3 f7 fw6 black-70 lh-title">
                <span className="red">Please Note:</span> The Payment methods below have{' '}
                <span className="red">lower success rate</span> at the moment
              </div>

              <ul>
                {down_payment_modes?.map((item) => {
                  if (!IS_SERVER && deviceWidth > 990 && item !== 'paytm') return <></>;
                  else if (
                    item.payment_option_name === 'whatsapp' &&
                    !IS_SERVER &&
                    getMobileOperatingSystem() !== 'Android'
                  )
                    return <></>;
                  else
                    return (
                      <li
                        key={item.payment_option_name}
                        className={item.payment_option_name}
                        onClick={() => {
                          item === 'paytm'
                            ? onSelect(item.payment_option_name, 2)
                            : onSelect(item.payment_option_name, 1);
                        }}
                      >
                        <div className="logo-wrap">
                          <img src={`//cdn.dotpe.in/cfe/image/${item}.png`} alt="logo" />
                        </div>
                        <span>{getPaymentMethodName(item.payment_option_name)}</span>
                      </li>
                    );
                })}
              </ul>
            </>
          )}
        </>
      );
    }
    return null;
  };

  const renderPaymentOptionOnNormalTransaction = () => {
    return (
      <>
        <ul>
          {/* remove removePaytmFromMethods when paytm is supported react native webview */}
          {removePaytmFromMethods(methods, false)?.map((item) => {
            if (!IS_SERVER && deviceWidth > 990 && item !== PAYMENT_TYPE_CONSTANTS.PAYTM)
              return <></>;
            else if (
              item === 'whatsapp' &&
              !IS_SERVER &&
              getMobileOperatingSystem() !== 'Android'
            )
              return <></>;
            else
              return (
                <li className={item} onClick={() => onTapOption(item)} key={item}>
                  <div className="logo-wrap">
                    <img
                      src={
                        item === 'paytmupi'
                          ? 'https://cdn.dotpe.in/kiranaStatic/custom_website/paytmupilogo21august2023.png'
                          : `//cdn.dotpe.in/cfe/image/${item}.png`
                      }
                      alt="logo"
                    />
                  </div>
                  <span>{getPaymentMethodName(item)}</span>
                </li>
              );
          })}
        </ul>

        {downPaymentMethods && downPaymentMethods.length > 0 && (
          <>
            <div className="pt4 pb3 f7 fw6 black-70 lh-title">
              <span className="red">Please Note:</span> The Payment methods below have{' '}
              <span className="red">lower success rate</span> at the moment
            </div>

            <ul>
              {downPaymentMethods?.map((item) => {
                if (!IS_SERVER && deviceWidth > 990 && item !== 'paytm') return <></>;
                else if (
                  item === 'whatsapp' &&
                  !IS_SERVER &&
                  getMobileOperatingSystem() !== 'Android'
                )
                  return <></>;
                else
                  return (
                    <li
                      className={item}
                      onClick={() => {
                        item === PAYMENT_TYPE_CONSTANTS.PAYTM
                          ? onSelect(item, 2)
                          : onSelect(item, 1);
                      }}
                      key={item}
                    >
                      <div className="logo-wrap">
                        <img src={`//cdn.dotpe.in/cfe/image/${item}.png`} alt="logo" />
                      </div>
                      <span>{getPaymentMethodName(item)}</span>
                    </li>
                  );
              })}
            </ul>
          </>
        )}
      </>
    );
  };
  return (
    <section
      className={
        'paymentOptions-wrapper ' +
        (orderDetails.payment_status === 0 || orderDetails.payment_status === 3
          ? ''
          : 'disabled')
      }
    >
      {store?.services?.mdr_flag
        ? renderPaymentOptionOnCustomerTransaction()
        : renderPaymentOptionOnNormalTransaction()}
    </section>
  );
};

export default PaymentOptionsGrid;
