import { useSelector } from 'react-redux';
import { roundnessCalculator } from '../../../DesignSystem/AtomicComponents/util';
import CommonCTA from '../CommonCTA';
import { getPaymentMethodName } from '../../../../utils/getPaymentMethodName';

const ProceedToPayButton = ({ paymentData, onClick }) => {
  const { theme, loader } = useSelector((state) => ({
    theme: state.storeReducer.store.theme,
    loader: state.commonReducer.loader,
  }));
  return (
    <CommonCTA
      text={
        'Pay using ' +
        getPaymentMethodName(
          paymentData.selectedPaymentMethod.payment_option_name || paymentData.method
        )
      }
      bgColor={'#2b74d8'}
      borderRadius={theme ? `${roundnessCalculator(theme?.roundness)}` : '8px'}
      onClick={() => onClick(...Object.values(paymentData))}
      isDisable={loader}
    />
  );
};

export default ProceedToPayButton;
