import GreatIcon from '../../../../assets/svgExports/GreatIcon';

const TotalAmountSaved = ({ amount }) =>
  !!amount && (
    <div class="flex items-center justify-center pv6px ph12px br3 mb3 tw-w-max tw-bg-[#cdffe4]">
      <GreatIcon />
      <span class="f13px fw5 ml8px tw-text-xs">
        Great! You’re saving <span class="fw7">₹ {amount}</span> on this order!
      </span>
    </div>
  );

export default TotalAmountSaved;
