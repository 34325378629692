/**
 * Helper function which returns the
 * hardcoded string for the payment method
 * @param {string} method
 * @return {string}
 */
export const getPaymentMethodName = (method) => {
  switch (method) {
    case 'googlepay':
      return 'Google Pay';
    case 'cod':
      return 'Cash';
    case 'phonepe':
    case 'pswitch':
      return 'PhonePe';
    case 'payzapp':
      return 'PayZapp';
    case 'whatsapp':
      return 'WhatsApp';
    case 'paytm':
      return 'Paytm';
    case 'paytmupi':
      return 'Paytm UPI';
    case 'airtel':
      return 'Airtel';
    case 'creditCard':
      return 'Credit Card';
    case 'debitCard':
      return 'Debit Card';
    case 'cemi':
      return 'EMI';
    case 'dupi':
      return 'dupi';
    case 'mobikwik':
      return 'MobiKwik';
    default:
      return method;
  }
};
