import PlaceOrderButton from '../PlaceOrderButton';
import ProceedToPayButton from '../ProceedToPayButton';

export const PaymentOrPlaceOrderCTA = ({
  state,
  onProceedToPay,
  onPlaceOrder,
  customClasses = '',
}) => {
  if (!(state.payOnDelivery || state.selectedPaymentData)) {
    return null;
  }

  return (
    <div
      className={
        customClasses ||
        'fixed bottom-0 left-0 right-0 w-100 pa20px bg-white shadow-3 z-999'
      }
    >
      {!!state.selectedPaymentData && (
        <ProceedToPayButton
          paymentData={state.selectedPaymentData}
          onClick={onProceedToPay}
        />
      )}
      {!!state.payOnDelivery && <PlaceOrderButton onClick={onPlaceOrder} />}
    </div>
  );
};
