import { useSelector } from 'react-redux';
import { roundnessCalculator } from '../../../DesignSystem/AtomicComponents/util';
import { isStoreServiceBased } from '@/utils/isStoreServiceBased';
import CommonCTA from '../CommonCTA';

const PlaceOrderButton = ({ onClick }) => {
  const { theme, loader } = useSelector((state) => ({
    theme: state.storeReducer.store.theme,
    loader: state.commonReducer.loader,
  }));

  return (
    <CommonCTA
      text={isStoreServiceBased() ? 'Confirm Booking' : 'Place Order'}
      bgColor={theme?.colors?.primary_color || '#EA5151'}
      borderRadius={theme ? `${roundnessCalculator(theme?.roundness)}` : '8px'}
      onClick={onClick}
      isDisable={loader}
    />
  );
};

export default PlaceOrderButton;
