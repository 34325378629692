import getCustomCtaText from '@/utils/getCustomCtaText';
import { COD_CHARGE_TYPE, PAYMENT_TYPE_CONSTANTS } from '../../../../utils/constants';
import { useSSRSelector } from '@/redux/ssrStore';

const SelectCashOnDelivery = ({
  isSelectedCOD,
  toggleCOD,
  codCharge,
  ctaType,
  unselectablePaymentOptions = [],
}) => {
  const { storeInfo } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
  }));

  const isCodDisabledDueToCoupon = unselectablePaymentOptions?.includes(
    PAYMENT_TYPE_CONSTANTS.COD
  );

  return (
    <div className="pt2">
      <p className="fw6 black-40">OR</p>
      <div
        className={`shadow-4 pa3 br3 ${isCodDisabledDueToCoupon ? 'tw-cursor-not-allowed tw-opacity-40' : ''}`}
        onClick={!isCodDisabledDueToCoupon && toggleCOD}
      >
        <input
          type="radio"
          checked={isSelectedCOD}
          disabled={isCodDisabledDueToCoupon}
        ></input>
        <span className="ml2">{getCustomCtaText(ctaType)}</span>
        {storeInfo?.services?.cod_charge_flag > COD_CHARGE_TYPE.FREE && (
          <div
            className="extra-cod-charges"
            style={{ marginTop: '4px', marginLeft: '21px' }}
          >
            {`Extra delivery fees ₹ ${codCharge}`}
          </div>
        )}
      </div>
      {isCodDisabledDueToCoupon && (
        <p className="tw-m-0 tw-pt-[8px] tw-text-[11px]/[15px] tw-text-[#111C36CC] ">
          You cannot select this option, the applied offer is not applicable on ‘Pay on
          Delivery’ orders.
        </p>
      )}
    </div>
  );
};

export default SelectCashOnDelivery;
