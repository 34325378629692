import { checkIfObjectExists } from './checkIfObjectExists';
import { PAYMENT_TYPE_CONSTANTS, PREPAID_POSTPAID_METHODS } from './constants';

/**
 * func to check for cod in payment method
 * @param {*} otherMethods
 * @param {*} paymentOptionsWithTxnCharge
 * @param {*} storeInfo
 * @return {*} array of cod method or empty array
 */
export const codMethodRender = (otherMethods, paymentOptionsWithTxnCharge, storeInfo) => {
  let method = otherMethods;

  if (storeInfo?.services?.mdr_flag && checkIfObjectExists(paymentOptionsWithTxnCharge))
    method = paymentOptionsWithTxnCharge?.others?.map((method) => {
      if (method.hasOwnProperty('payment_option_name'))
        return method['payment_option_name'];
    });

  if (
    storeInfo?.services?.payment_method !== PREPAID_POSTPAID_METHODS.PREPAID &&
    method?.length &&
    method.includes(PAYMENT_TYPE_CONSTANTS.COD)
  ) {
    return method;
  }
  return [];
};
