const CommonCTA = ({ text, onClick, bgColor, borderRadius, isDisable }) => {
  return (
    <button
      className={`w-100 white bn f16px fw6 shadow-4 tw-p-3`}
      style={{
        backgroundColor: bgColor,
        borderRadius: borderRadius,
      }}
      onClick={onClick}
      disabled={isDisable}
    >
      {text}
    </button>
  );
};

export default CommonCTA;
