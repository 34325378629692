const GreatIcon = () => (
  <svg
    id="greatIcon"
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.0448 14H6.45968C6.68576 14 6.8687 13.8166 6.8687 13.5898V13.1475C6.86911 12.5123 7.18863 11.8713 7.74626 11.4363C8.50799 10.8427 8.91222 10.2783 8.98292 9.71116C9.05761 9.10995 8.84312 8.74904 8.6502 8.55239C8.51585 8.4157 8.22329 8.32243 7.96618 8.43177C7.78733 8.50779 7.65423 8.66337 7.60638 8.85218C7.34177 9.89639 6.44825 10.6641 5.31696 10.6641C3.96157 10.6641 2.86284 9.56227 2.86284 8.20312C2.86284 6.84398 3.96157 5.74219 5.31696 5.74219C6.08152 5.74219 6.76047 6.09457 7.20837 6.64511C7.41629 6.90066 7.76189 6.99743 8.07176 6.88693C8.38158 6.77644 8.58904 6.48249 8.58912 6.15234C8.58912 4.7116 6.24843 4.10156 5.31696 4.10156C4.2261 4.10156 3.19957 4.52854 2.42587 5.304C1.01777 6.71677 1.26158 8.41228 1.23196 8.58725C1.22596 9.77927 1.21797 11.4662 1.64297 13.6668C1.6797 13.8602 1.84866 14 2.0448 14Z"
      fill="#0E0E0E"
    ></path>
    <path
      d="M6.74777 3.50666C7.48322 2.84871 7.22215 3.10085 7.61994 2.77075C7.97014 2.49177 8.03591 1.97617 7.75134 1.6172C7.47853 1.27355 6.94328 1.21344 6.60298 1.48582C6.4169 1.64703 5.72478 2.11212 4.62305 3.33557C4.85142 3.30316 5.08219 3.28104 5.31683 3.28104C5.71543 3.28104 6.2251 3.3558 6.74777 3.50666Z"
      fill="#0E0E0E"
    ></path>
    <path
      d="M4.45597 0.0840805C4.06214 -0.116212 3.55406 0.0552329 3.35795 0.450979C3.22163 0.775467 2.40635 2.03292 1.89551 4.67983C2.28162 4.30292 2.71919 4.00006 3.19087 3.77169C3.52545 3.35158 3.86161 2.95463 4.20129 2.58918C4.57972 1.58812 4.65195 1.55006 4.82186 1.18316C5.02737 0.769342 4.85068 0.281147 4.45597 0.0840805Z"
      fill="#0E0E0E"
    ></path>
    <path
      d="M0.854387 6.18259C0.987509 4.83194 1.25842 3.52518 1.67641 2.30182C1.65203 1.9451 1.63106 1.58804 1.64096 1.22968L1.65135 0.843145C1.66408 0.406055 1.33002 0.0125781 0.832927 0C0.38595 0 0.0272666 0.350875 0.0160867 0.798273L0.0056975 1.18519C-0.0411489 2.88654 0.202518 4.91189 0.676654 6.65591C0.728163 6.49351 0.787389 6.33574 0.854387 6.18259Z"
      fill="#0E0E0E"
    ></path>
  </svg>
);

export default GreatIcon;
